import type { EChartsType } from "echarts/core";
import { ChronoUnit, chronoUnitToMs } from "../utils/chronoUnit";
import { DynamicRange, dynamicRangeToDate } from "../utils/dynamicRangeToDate";

async function loadEcharts() {
	const { LineChart } = await import("echarts/charts");
	const { TooltipComponent, GridComponent, DatasetComponent, LegendComponent } = await import("echarts/components");
	const { CanvasRenderer } = await import("echarts/renderers");
	const { UniversalTransition } = await import("echarts/features");
	const translations = (await import("../echart/locale")).default;
	const echarts = await import("echarts/core");

	echarts.use([
		GridComponent,
		DatasetComponent,
		TooltipComponent,
		LineChart,
		CanvasRenderer,
		UniversalTransition,
		LegendComponent
	]);
	echarts.registerLocale("de", translations);
	return echarts;
}

export type DatePointRecord<D extends string> = {
	[key in D]: number;
};

export interface LineChartOptions<DataPointName extends string> {
	data: {
		start?: number;
		end?: number;
		relativeUnit?: ChronoUnit;
		relativeValue?: number;
		dynamicRange?: DynamicRange;
		aggregateUnit: ChronoUnit;
		aggregateValue: number;
		observationId?: number;
		dataPoints: DataPointName[];
		dataSource: string;
	};
	presentation: {
		smooth: boolean;
		stacked: boolean;
		stepped: boolean;
		showSymbols: boolean;
		showLegend: boolean;
		filled: boolean;
		dynamicYAxisScaling: boolean;
		tooltip: {
			decimalPlaces: number;
			suffix: string;
		};
		labels: {
			dataPoints: {
				[key in DataPointName]: string;
			};
			xAxis: string;
			yAxis: string;
		};
		colors: {
			[key in DataPointName]: string;
		};
	};
}

const fontSize = 16;

export abstract class AbstractLineChart<D extends string> {
	protected readonly el;

	protected readonly options: LineChartOptions<D>;

	protected chartInstance: EChartsType | null = null;

	private readonly resizeObserver: ResizeObserver;

	public constructor(el: HTMLElement, options: LineChartOptions<D>) {
		this.el = el;
		this.options = options;
		this.resizeObserver = new ResizeObserver(() => {
			if (this.chartInstance) {
				this.chartInstance.resize();
			}
		});
		this.resizeObserver.observe(el);

		this.createChart();
	}

	protected abstract loadData(): Promise<any[]>;

	private createDimensions() {
		const dimensions = [
			{
				name: "time",
				type: "time",
				displayName: "Zeit"
			}
		];
		for (const dataPoint of this.options.data.dataPoints) {
			dimensions.push({
				name: dataPoint,
				type: "int",
				displayName: this.options.presentation.labels.dataPoints[dataPoint]
			});
		}
		return dimensions;
	}

	private createSeries() {
		const series = [];
		const presentation = this.options.presentation;
		for (const dataPoint of this.options.data.dataPoints) {
			const line: any = {
				type: "line",
				name: presentation.labels.dataPoints[dataPoint],
				encode: {
					x: "time",
					y: dataPoint
				},
				animationEasing: "cubicInOut",
				step: presentation.stepped,
				symbol: presentation.showSymbols ? "emptyCircle" : "none",
				symbolSize: 6,
				smooth: presentation.smooth,
				stack: presentation.stacked ? "stack" : undefined,
				tooltip: {
					valueFormatter: (value: number) => {
						let fmt = value.toFixed(presentation.tooltip?.decimalPlaces ?? 0);
						if (presentation.tooltip?.suffix) {
							fmt += presentation.tooltip?.suffix ?? "";
						}
						return fmt;
					}
				},
				emphasis: {
					focus: "series"
				},
				lineStyle: {
					width: 3
				}
			};
			if (presentation.filled) {
				line.areaStyle = {
					opacity: 0.3
				};
			}
			if (presentation.colors[dataPoint]) {
				line.itemStyle = {
					color: presentation.colors[dataPoint]
				};
			}
			series.push(line);
		}
		return series;
	}

	protected getTime(): [number, number] {
		const dataOptions = this.options.data;

		// Absoluter Zeitraum
		if (dataOptions.start && dataOptions.end) {
			return [dataOptions.start, dataOptions.end];
		}

		if (dataOptions.relativeUnit && dataOptions.relativeValue) {
			const end = Date.now();
			const start = end - chronoUnitToMs(dataOptions.relativeValue, dataOptions.relativeUnit);
			return [start, end];
		}

		if (dataOptions.dynamicRange) {
			const [start, end] = dynamicRangeToDate(dataOptions.dynamicRange);
			return [start.getTime(), end.getTime()];
		}
		throw new Error("Invalid time settings");
	}

	private async createChart(): Promise<void> {
		const echarts = await loadEcharts();
		const chart = echarts.init(this.el);
		this.chartInstance = chart;

		this.loadData().then((rows) => {
			chart.hideLoading();
			chart.setOption({
				dataset: {
					dimensions: this.createDimensions(),
					source: rows
				}
			});
		});
		chart.showLoading();

		if (this.options.presentation.showLegend) {
			chart.setOption({
				legend: {
					textStyle: {
						fontSize
					}
				}
			});
		}

		chart.setOption({
			tooltip: {
				trigger: "axis",
				type: "line",
				textStyle: {
					fontSize
				}
			},
			xAxis: {
				name: this.options.presentation.labels.xAxis,
				nameTextStyle: {
					fontSize
				},
				type: "time",
				axisLabel: {
					fontSize,
					formatter: {
						day: "{dayStyle|{dd}. {MMM}}"
					},
					rich: {
						dayStyle: {
							fontSize: 14,
							fontWeight: "bold"
						}
					}
				},
				axisPointer: {
					label: {
						formatter: (data: any) => {
							const date = new Date(parseInt(data.value));
							return date.toLocaleString("de-DE", {
								dateStyle: "medium",
								timeStyle: "short"
							});
						}
					}
				}
			},
			yAxis: {
				name: this.options.presentation.labels.yAxis,
				type: "value",
				scale: this.options.presentation.dynamicYAxisScaling ?? false,
				nameTextStyle: {
					fontSize
				},
				axisLabel: {
					fontSize
				}
			},
			series: this.createSeries()
		});
	}
}
